import React, { useEffect, useState, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF, useFBX, useTexture, useAnimations } from "@react-three/drei";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import createAnimation from "./converter";
import blinkData from "./blendDataBlinkSmile.json";

import * as THREE from "three";

const _ = require("lodash");
let idleClipAction = undefined;

function Avatar({
  backendAPI,
  textToVoiceAPI,
  avatar_url,
  backendData,
  setAudioSource,
  playing,
  paused,
  setPaused,
  queueInterval,
  speakingInterval,
  setSpeakingInterval,
  responseArray,
  startSpeak,
  setStartSpeak,
  waitForAzure,
  setWaitForAzure,
  waitResponse,
  setWaitResponse,
  emotion,
  waitingSpeeches,
  animations,
  setAnimations,
  playingAnimation,
  setPlayingAnimation,
  setDisplayForm,
  skipped,
  setSkipped,
}) {
  let gltf = useGLTF(avatar_url);

  // let animation1 = useGLTF('animations/cyndy-avatar-5.glb');
  let { clips: idleClipsMain } = useAnimations(gltf.animations);
  // let { clips: idleClips1 } = useAnimations(animation1.animations);
  let morphTargetDictionary = {};

  let texturesArray = [],
    colorsArray = [],
    textureNamesArray = [],
    colorNamesArray = [];

  for (let i = 0; i < backendData.textures.length; i++) {
    if (backendData.textures[i].texture_image) {
      textureNamesArray.push(backendData.textures[i].object_name);
      texturesArray.push(backendAPI + backendData.textures[i].texture_image);
    } else {
      colorNamesArray.push(backendData.textures[i].object_name);
      colorsArray.push(backendData.textures[i].texture_color);
    }
  }

  const threeTextures = useTexture(texturesArray);

  _.each(threeTextures, (t) => {
    t.encoding = THREE.sRGBEncoding;
    t.flipY = false;
  });

  gltf.scene.traverse((node) => {
    if (
      node.type === "Mesh" ||
      node.type === "LineSegments" ||
      node.type === "SkinnedMesh"
    ) {
      if (node.name.includes("Classic_short")) {
        node.material.ior = 1;
      }

      node.castShadow = true;
      node.receiveShadow = true;

      if (node.morphTargetDictionary) {
        morphTargetDictionary[node.name] = node.morphTargetDictionary;
      }
    }
  });

  useEffect(() => {
    if (playingAnimation) {
      if (idleClipAction) idleClipAction.stop();
      idleClipAction = mixer.clipAction(idleClipsMain[playingAnimation]);
      idleClipAction.timeScale = 1;
      idleClipAction.play();
    }
  }, [playingAnimation]);

  useEffect(() => {
    console.log("main", idleClipsMain);
    setAnimations(idleClipsMain);
    // let idleClipAction1 = mixer.clipAction(idleClipsMain[3]);
    // let idleClipAction2 = mixer.clipAction(idleClips1[0]);
    // let idleClipAction3 = mixer.clipAction(idleClips2[2]);
    // let idleClipAction4 = mixer.clipAction(idleClips3[2]);
    // let idleClipAction5 = mixer.clipAction(idleClips4[3]);
    // let idleClipAction6 = mixer.clipAction(idleClips5[6]);
    // idleClipAction.setLoop(THREE.LoopOnce);
    // idleClipAction.reset();
    // idleClipAction1.timeScale = 0.5;
    // idleClipAction.setLoop(THREE.LoopOnce);
    // idleClipAction.clampWhenFinished = true;
    // idleClipAction1.play();

    // idleClips.forEach(idleClip => {
    //   let idleClipAction = mixer.clipAction(idleClip);
    //   idleClipAction.play();
    // });

    gltf.scene.traverse((node) => {
      if (node.type === "Mesh") {
        let styles = [];
        // Find matching Textures
        textureNamesArray.forEach((val, index) => {
          if (
            node.name
              .toLowerCase()
              .includes(textureNamesArray[index].toLowerCase())
          )
            styles.push(threeTextures[index]);
        });

        // Find matching colors
        colorNamesArray.forEach((val, index) => {
          if (
            node.name
              .toLowerCase()
              .includes(colorNamesArray[index].toLowerCase())
          )
            styles.push(colorsArray[index]);
        });

        if (styles.length > 0) {
          const texture = styles.sample();
          if (typeof texture === "string") {
            node.material.color.setHex(texture);
          } else {
            node.material = new THREE.MeshStandardMaterial();
            node.material.map = texture;
          }
        }
      }
    });
  }, []);

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {
    if (!startSpeak && !waitForAzure) return;

    if (waitForAzure) {
      setClips([]);
      setWaitForAzure(false);
    }

    if (speakingInterval >= queueInterval) {
      setDisplayForm(true);
      setStartSpeak(false);
      return;
    }
    if (queueInterval <= 0) return;

    if (!responseArray[speakingInterval]) {
      const intervalId = setTimeout(() => {
        setWaitForAzure(true);
        setStartSpeak(!startSpeak);
      }, 1000);
      return () => clearTimeout(intervalId);
    }

    let { blendData, filename } = responseArray[speakingInterval];

    // inja timer bezzarim bebinim in tike cheghad zaman mibare
    // ke masale max kardano kamtar konim

    let newClips = [];

    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      newClips.push(
        createAnimation(
          backendData.created_using_blender,
          blendData,
          value,
          key,
          emotion,
        ),
      );
    }

    filename = textToVoiceAPI + filename;

    setClips(newClips);
    setAudioSource(filename);
    setSpeakingInterval(speakingInterval + 1);
    if (backendData.contact_form_in_nth_sentence)
      if (speakingInterval === backendData.contact_form_in_nth_sentence)
        setDisplayForm(true);

    setStartSpeak(false);
  }, [startSpeak]);

  useEffect(() => {
    if (skipped) {
      console.log("umad skipped");
      setClips([]);
      setStartSpeak(false);
      setSpeakingInterval(0);
      setSkipped(false);
    }
  }, [skipped]);

  useEffect(() => {
    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      let blink = createAnimation(
        backendData.created_using_blender,
        blinkData,
        value,
        key,
      );
      let blinkAction = mixer.clipAction(blink);
      blinkAction.play();
    }
  }, []);

  useEffect(() => {
    if (!waitResponse) return;
    if (waitingSpeeches.length === 0) return;

    const random_number = Math.floor(Math.random() * waitingSpeeches.length);

    const waitData = waitingSpeeches[random_number].blendData;
    const filename = `${textToVoiceAPI}${waitingSpeeches[random_number].filename}`;

    let newClips = [];

    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      newClips.push(
        createAnimation(
          backendData.created_using_blender,
          waitData,
          value,
          key,
          0,
        ),
      );
    }

    setClips(newClips);
    setAudioSource(filename);
    setWaitResponse(false);
  }, [waitResponse]);

  // Play animation clips when available
  useEffect(() => {
    if (skipped) {
      _.each(clips, (clip) => {
        animations[clip.uuid].stop();
      });
    }
    if (playing === false) {
      if (paused) {
        _.each(clips, (clip) => {
          animations[clip.uuid].paused = true;
        });
      } else {
        // BUG BUG BUG
        // _.each(animations, (animation) => {
        //     animation.stop();
        //   });
        setPaused(null);
      }
    } else {
      if (paused === false) {
        setPaused(false);
        _.each(clips, (clip) => {
          animations[clip.uuid].paused = false;
        });
      } else {
        let total_clips_duration = 0;
        _.each(clips, (clip) => {
          total_clips_duration = clip.duration;
          animations[clip.uuid] = mixer.clipAction(clip);
          animations[clip.uuid].setLoop(THREE.LoopOnce);
          // animations[clip.uuid].startAt(paused);
          animations[clip.uuid].play();
        });

        let finished = true;
        if (idleClipAction) {
          if (!idleClipAction.enabled) {
            finished = !idleClipAction.enabled;
            idleClipAction.stop();
          }
        }
        if (finished) {
          idleClipAction = undefined;
          let talking_ideal_animations = [
            "describe-by-hand-middle",
            "Rubbing-hand-middle",
          ];
          let random = Math.floor(
            Math.random() * talking_ideal_animations.length,
          );
          const the_animation = animations.find(
            (animation) => animation.name === talking_ideal_animations[random],
          );
          if (the_animation) {
            const animation_speed = 1;
            const animation_time = the_animation.duration / animation_speed;
            const number_of_repeats = Math.floor(
              total_clips_duration / animation_time,
            );
            idleClipAction = mixer.clipAction(the_animation);
            idleClipAction.timeScale = animation_speed;
            idleClipAction.setLoop(THREE.LoopRepeat, number_of_repeats);
            // idleClipAction.syncWith(clips);
            idleClipAction.play();
          }
        }
      }
    }
  }, [playing, skipped]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <primitive
      object={gltf.scene}
      position={
        window.location.hostname === "localhostd"
          ? [0, -160, 1]
          : JSON.parse(backendData.model_position)
      }
    />
  );
}

export default Avatar;
